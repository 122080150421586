import about from '@/mixins/about'

import LogoWhite from '@/components/icons/logoWhite'

import { Mixins } from '@/mixins/bgImg.js'

export default {
  name: 'About',

  mixins: [
    about,
    Mixins
  ],

  components: {
    LogoWhite
  },

  data() {
    return {
      members: []
    }
  },

  methods: {
    getMembers: async function() {
      let response = await this.getAboutMembers();

      if (response !== null) {
        this.members = response;
      } else {
        this.$toast.error('Error al obtener los miembros');
      }
    }
  },

  mounted: async function() {
    await this.getMembers();

    await this.bgImg();
  }
}
